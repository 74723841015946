<template>
  <fm-modal class="type-form" v-model="modal" v-if="modal" width="600px" title="活动类型">
    <div class="form">
      <div class="form-item">
        <div class="label">类型名称:</div>
        <div class="value">
          <fm-input-new v-model="formData.name" style="width:100%;"></fm-input-new>
        </div>
      </div>
      <div class="form-item">
        <div class="label">字段:</div>
        <div class="value">
        <fm-table-new
          v-if="modal"
          :columns="columns"
          border="row"
          :data-list="keyList"
          :stripe="false"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
        </fm-table-new>
        <div class="add-key">
          <fm-btn size="small" @click="chooseData = null;openDialog=true;">+添加字段</fm-btn>
        </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <fm-btn @click="save" style="margin-right: 30px;">保存</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
    <fm-form-dialog
      form-title="字段"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </fm-modal>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  activityTypeRequest
} from '../../../api'

export default {
  components: {
    TableActions
  },
  props: {
    data: Object
  },
  watch: {
    data: {
      handler (value) {
        if (value) {
          this.formData = Object.assign({}, value)
          this.keyList = this.formData.keyList || []
        } else {
          this.formData = {}
          this.keyList = []
        }
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      columns: [{
        field: 'index',
        title: '序号'
      },
      {
        field: 'key',
        title: '字段名称'
      },
      {
        field: 'type',
        title: '字段类型',
        render: (h, rowData) => {
          return h('div', rowData && rowData.type ? (this.$store.getters.activityTypeKeyTypeList.find(v => v.key === rowData.type)|| {label: ''}).label : '-')
        }
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions',
        width: 90,
        fixed: 'right',
        search: false,
        export: false,
        configurable: false
      }],
      tableActions: [{
        key: 'editKey',
        label: '修改'
      },
      {
        key: 'delKey',
        label: '删除'
      }],
      chooseData: {},
      formData: {},
      modal: false,
      openDialog: false,
      loading: false,
      keyList: []
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'input',
        label: '字段名称',
        key: 'key',
        check: {
          required: true
        }
      },
      {
        type: 'select',
        label: '字段类型',
        selectDatas: this.$store.getters.activityTypeKeyTypeList,
        key: 'type',
        check: {
          required: true
        }
      }]
    }
  },
  methods: {
    async save () {
      if (!this.formData.name) {
        this.$notify({
          title: '系统提示',
          message: '请输入类型名称',
          type: 'info'
        })
        return
      }
      let config = JSON.stringify(this.keyList)
      let parm = {
        name: this.formData.name,
        config
      }
      if (!this.formData.id) {
        await activityTypeRequest.add(parm)
      } else {
        if (this.formData.activityNum) {
           const result = await this.$dialog.confirm({title: '系统提示', content: '该活动类型已有活动开展，修改可能造成信息丢失，确定修改该活动类型吗?'})
           if (!result) {
            return
           }
        }
        await activityTypeRequest.update(this.formData.id, parm)
      }
      this.modal = false
      this.$emit('dataUpdate')
    },
    async formSubmit (data, resave) {
      if (data.index) {
        let otherData = this.keyList.filter(v => v.index !== data.index)
        if (otherData.find(v => v.key === data.key)) {
          this.$notify({
            title: '系统提示',
            message: '该字段已存在',
            type: 'info'
          })
          resave()
          return
        }
        this.keyList = this.keyList.map(v => v.index === data.index ? data : v)
      } else {
        if (this.keyList.find(v => v.key === data.key)) {
          this.$notify({
            title: '系统提示',
            message: '该字段已存在',
            type: 'info'
          })
          resave()
          return
        }
        data.index = this.keyList.length + 1
        this.keyList.push(data)
      }
      resave()
      this.openDialog = false
    },
    tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'editKey') {
        this.openDialog = true
      } else if (parm.action === 'delKey') {
        let lastData = this.keyList.filter(v => v.index !== this.chooseData.index)
        lastData.forEach((v, index) => v.index = index + 1)
        this.keyList = lastData
      }
    },
    open () {
      this.modal = true
    }
  }
}
</script>

<style lang="less" scoped>
.type-form {
  .form {
    padding: 0 20px;
  }
  .btns {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .add-key {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .form-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .label {
      width: 7rem;
    }
    .value {
      flex: 1;
    }
  }
}
</style>